/* TextBox remove blue box shadow when active */
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover, *:focus, .form-control:focus
{
    outline:0px !important;
    -webkit-appearance:none;
	box-shadow: none;
}