header {
    position:fixed;
    top: 0;
    width:100%;
    background-color:white;
    z-index:1;

    .navbar-nav {
        @media(min-width: @screen-md) {
            float: right;
        }

        @media(min-width: @screen-lg) {
            margin-top: 10px;
        }
    }

    @media(min-width: @screen-sm) {
        #navbar {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .navbar {
        padding: 20px 0;
        margin-bottom:0px;
        @media(min-width: @screen-md) {
            padding: 30px 0;
        }

        .navbar-header {
            @media(max-width: @screen-md) {
                display: flex;
                width: 100%;
                align-items: center;

                .navbar-brand {
                    margin-right: auto;
                }
            }

            .navbar-brand {
                padding-top: 0;
                padding-bottom: 0;
                height: 100%;

                img {
                    max-width: 220px;

                    @media(min-width: @screen-sm) {
                        max-width: 330px;
                    }

                    @media(min-width: @screen-md) {
                        max-width: 220px;
                    }

                    @media(min-width: @screen-lg) {
                        max-width: 330px;
                    }
                }
            }

            .navbar-toggle {
                margin-right: 0px;
                padding-right: 0px;

                @media(max-width: @screen-md) {
                    display: inline-block !important;
                }

                .icon-bar {
                    background-color: @brand-primary;
                }
            }
        }

        .navbar-nav {
            width: 100%;

            @media(min-width: @screen-md) {
                width: auto;
            }

            & > li {

                &.active > a {
                    background-color: transparent;
                }

                & > a {
                    font-size: 15px;
                    border-bottom: 1px solid @body-color;
                    .transition(all .5s);
                    padding-right: 30px;

                    @media(min-width: @screen-sm) {
                        padding-right: 0;
                    }

                    @media(min-width: @screen-md) {
                        padding-top: 14px;
                        padding-bottom: 5px;
                        padding-left: 3px;
                        padding-right: 3px;
                        margin: 0 14px;
                    }


                    &:hover, &:focus, &:active {
                        background-color: transparent;
                        border-color: @brand-secondary;
                    }

                    &.btn {
                        .btn();
                        font-size: 14px;
                        border: 1px solid @brand-secondary;

                        &:hover, &:focus, &:active {
                            background-color: @brand-secondary;
                        }

                        margin-left: 15px;
                        margin-right: 15px;

                        @media(min-width: @screen-md) {
                            margin-left: 14px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .ios-button-wrapper{
            background: transparent;
            border: 0;
        }
    }


    @media(max-width:@screen-md) {

        .navbar-collapse.collapse {
            display: none !important;
        }

        .navbar-collapse {
            overflow-x: visible !important;
            padding-right: unset !important;
            padding-left: 0px !important;
        }

        .navbar-collapse.in {
            overflow-y: auto !important;
        }

        .collapse.in {
            display: block !important;
        }

        .nav-item {
            text-align: right;
            width: 100%;

            @media(min-width: @screen-md) {
                text-align: right;
            }

            .btn {
                @media(min-width:@screen-sm) {
                    margin-left: 0px !important;
                }
            }
        }

        .navbar-nav {
            padding-left: 0px !important;
            margin-right: 0px;
            margin-left: 0px;
        }

        .navbar-header {
            float: initial !important;
        }
    }


    .page-header {
        border-bottom: none;
    }

    #loginModal, #confirmationModal, .modal-theme-blue {
        padding: 0 !important;
        text-align: center;

        @media(min-width: @screen-sm) {
            &:before {
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                margin-right: -4px;
            }
        }

        .modal-dialog {
            display: inline-block;
            vertical-align: middle;
            max-width: 500px;

            @media(max-width: @screen-xs-max) {
                width: 90%;
            }

            .modal-content {
                background-color: @brand-primary;

                .modal-header {
                    border-bottom: none;
                    position: absolute;
                    right: 0;
                    z-index: 1;

                    .close {
                        color: @brand-secondary;
                        opacity: 1;
                        text-shadow: none;
                        font-size: 35px;
                    }
                }

                .modal-body {
                    text-align: center;
                    margin: 0 auto;
                    padding-top: 50px;
                    padding-bottom: 50px;

                    @media(min-width: @screen-sm) {
                        max-width: 400px;
                        min-width: 400px;
                    }

                    h2 {
                        color: @white;
                        text-transform: uppercase;
                        margin-top: 0;
                    }

                    .form-control {
                        &::placeholder {
                            color: @brand-secondary-light;
                        }
                    }

                    a {
                        color: @white;
                        margin-top: 20px;

                        &:hover {
                            color: @brand-secondary;
                        }
                    }

                    p {
                        color: white;
                    }
                }
            }
        }
    }
}
