.submissions-page {
    .submissions-page__categories {
        padding-left: 0;
        list-style-type: none;
    }

    .submissions-page__category {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 15px;

        @media(min-width: @screen-sm) {
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 0;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .submission-set {
        margin-top: -50px;

        .submission-item {

            &> div{
                width: 100%;
            }
            &:nth-child(even) {
                padding-right: 0px;
            }

            &:nth-child(odd) {
                padding-left: 0px;
            }

            @media(max-width:@screen-md) {
                padding-left: 0px;
                padding-right: 0px;
            }

            @media(min-width:@screen-md) {
                height: 450px;
            }

            padding-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            /** filtr requires fixed height. */
            h2 {
                text-transform: uppercase;
            }

            p {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .btn {
                text-transform: none;
            }

            .submission-metadata {
                color: #927D54;
                font-weight: bold;
                margin-bottom: 15px;
            }
        }
    }
}
