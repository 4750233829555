.news-page {
    .news-collection {
        .news-collection-item {
            padding-bottom: 35px;

            .img-col {
                @media(min-width: @screen-sm) {
                    padding-right: 60px;
                }

                .article-thumb-container {
                    .article-thumb {
                        -webkit-clip-path: polygon(0 0, 100% 0, 66% 100%, 0% 100%);
                        clip-path: polygon(0 0, 100% 0, 78% 100%, 0% 100%);
                    }
                }
            }

            .copy-col {
                padding-top: 20px;
                text-align: center;

                @media(min-width: @screen-sm) {
                    text-align: left;
                }

                h2 {
                    text-transform: uppercase;
                    margin-top: 0;
                    margin-bottom: 15px;
                    font-size: 35px;
                }

                p {
                    &.gold-text {
                        color: @brand-secondary;
                        font-weight: bold;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    .news-item {
        @media(min-width:@screen-md) {
            height: 400px;
        }

        //left item
        &:nth-of-type(odd){
            padding-left:0px;
        }

        //right item
        &:nth-of-type(even){
            padding-right:0px;
        }

        @media(max-width:@screen-md) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.news-item-page {
    .page-header {
        position: relative;

        .btn {
            text-transform: none;
            margin-bottom: 20px;

            @media(min-width: @screen-md) {
                position: absolute;
                right: 0;
                bottom: 45px;
                width: auto;
                margin-bottom: 0;
            }
        }
    }

    .news-pager {
        margin-top: 35px;

        .btn {
            @media(max-width: @screen-xs-max) {
                margin-bottom: 15px;
            }
        }
    }

    p {
        &.gold-text {
            color: @brand-secondary;
            font-weight: bold;
        }
    }

    .article-featured-image-container {
        margin-bottom: 35px;

        img {
            .border-radius(15px);
            width: 100%;
            max-height: 400px;
            object-fit: cover;
        }
    }
}

.umb-grid {
    img {
        max-width: 100%;
    }
}


.auth-news-item {
    &:nth-child(odd) {
        padding-left: 0px;

        @media(max-width:@screen-md) {
            padding-right: 0px;
        }
    }

    &:nth-child(even) {
        padding-right: 0px;

        @media(max-width:@screen-md) {
            padding-left:0px;
        }
    }
}


.panel-group .panel + .panel{
    margin-top:0px;
}
