.homepage-intro {
    position: relative;
    overflow: hidden;

    #homepage-bg-image {
        display: block;
        position: absolute;
        margin-top: 0px;
        margin-bottom: 0px;
        height: auto;
        width: 100%;
        right: 0;

        @media(min-width: @screen-md) {
            display: none;
        }
    }

    #homepage-bg-image-md {
        display: none;

        @media(min-width: @screen-md) {
            display: block;
            position: absolute;
            margin-top: 0px;
            margin-bottom: 0px;
            height: 100%;
            width: auto;
            right: 0;
        }
    }

    h3.home-title {
        line-height: 1;
        font-weight: 900;
        letter-spacing: 1px;
        margin-bottom: 35px;
        font-size: 77px;
        color: @brand-primary;
        text-transform: uppercase;

        @media(max-width: @screen-xs-max) {
            font-size: 50px;
        }
    }

    p {
        font-size: 19px;
        margin-bottom: 35px;

        @media(max-width: @screen-xs-max) {
            font-size: 17px;
        }
    }

    img {
        width: 100%;
        margin-top: 0;
        margin-bottom: 30px;

        @media(min-width: @screen-md) {
            margin-top: 50px;
            width: 108%;
        }
    }
}

.homepage-links {
    background-color: @brand-primary;

    div.col-md-3 {
        @media(max-width: @screen-sm-max) {
            margin-bottom: 30px;
            padding-left: 0px;
            padding-right: 0px;
        }

        @media(max-width: @screen-xs-max) {
            &:last-child {
                margin-bottom: 0;
            }
        }

        div.homepage-link-item {
            border-top: 1px solid @brand-secondary;
            text-align: center;
            max-width: 235px;
            margin: 0 auto;

            a {
                &:hover, &:active, &:visited {
                    text-decoration: none;
                    color: white;
                }
            }

            h2 {
                text-transform: uppercase;
                margin-top: 0;
                line-height: 1;
                font-size: 25px;
                color: white !important;
            }

            p {
                color: white;
            }

            img {
                width: 61px;
                height: 61px;
                margin: 30px auto;
            }
        }
        // Hide border
        &:first-child {
            div.homepage-link-item {
                border-color: @brand-primary;
            }
        }

        @media(min-width: @screen-sm) {
            & {
                div.homepage-link-item {
                    border-color: @brand-primary;
                }
            }
        }
    }
}

#newsCarousel {
    &.member_carousel {
        margin-bottom: 80px;
    }

    & .item, & .carousel-item {
        .h1 {
            font-size: 55px;
        }
    }

    & .item {
        height: 600px;

        @media(min-width:@screen-sm) {
            height: 500px;
        }

        @media(min-width:@screen-md) {
            height: 400px;
        }

        @media(min-width:@screen-lg){
            height: 300px;
        }
    }

    & .carousel-item {
        height: 400px;

        @media(max-width:@screen-md) {
            height: 500px;
        }

        @media(max-width:@screen-sm) {
            height: 750px;
        }
    }
}
