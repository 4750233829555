.authenticated-layout {
    #welfare {
        #welfare-numbers {
            margin-bottom: 2rem;

            a {
                color: @brand-secondary;
            }
        }

        #welfare-resources {

            .gold {
                margin-bottom: 1rem;
            }

            p, .blue {
                margin-bottom: 3rem;
            }
        }
    }
}
