.icon-text-row {
    text-align: center;
    margin-top: 30px;

    @media(min-width: @screen-md) {
        text-align: left;
        margin-top: 50px;
    }

    .image-col {
        display: inline-block;

        @media(min-width: @screen-md) {
            position: absolute;
        }

        img {
            width: 90px;
        }
    }

    .copy-col {
        display: inline-block;

        @media(min-width: @screen-md) {
            padding-left: 140px;
        }

        h2 {
            .h6();
            margin-top: 0;
            text-transform: uppercase;
        }
    }
}

.button-row-filter {
    padding-left: 0;
    list-style-type: none;
        
    li {

        &:first-child{
            padding-left:0px;
        }

        &:last-child{
            padding-right:0px;
        }
         @media(max-width:@screen-sm){
             padding-left:0px;
             padding-right:0px;
         }

        padding-bottom: 15px;

        @media(min-width: @screen-sm) {
            padding-bottom: 0;
        }

        &.active {
            span.btn {
                color: @brand-primary;
                background-color: @brand-secondary-light;
            }
        }
    }
}