.footer {
    background-color: @brand-primary;
    color: @white;
    padding-top: 40px;
    padding-bottom: 40px;

    h6 {
        color: @brand-secondary-light;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 0;
    }

    &-intro {
        margin-bottom: 25px;

        span {
            font-size: 14px;
        }
    }

    p, a {
        color: white;
    }

    &-info {
        margin-bottom: 15px;

        span {
            font-size: 14px;
            color: @white;
            display: block;
        }
    }

    &-form {
        h5 {
            color: @white;
            text-transform: uppercase;
            font-size: 35px;
            margin-top: 20px;
            margin-bottom: 15px;
            line-height: 1;

            @media(min-width: @screen-sm) {
                margin-top: 0;
                margin-bottom: 35px;
            }
        }

        .form-control {
            &::placeholder {
                color: @brand-secondary-light;
            }
        }
    }

    &-social {
        margin-top: 30px;
        margin-bottom: 30px;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                .display-inline-block();

                img {
                    margin-right: 48px;

                    &.facebook-icon, &.linkedin-icon {
                        width: 28px;
                    }

                    &.twitter-icon {
                        width: 32px;
                    }
                }
            }
        }
    }

    &-copy {
        margin-top: 35px;
        font-size: 12px;
    }

    &-logo {

        h6{
            margin-bottom:20px;
        }

        img {
            width: 180px;
        }
    }
}
