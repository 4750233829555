.committee-member-details {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #ECECEC;
    padding: 30px 15px;
    border-radius: 8px;
    position: relative;

    @media(min-width:@screen-sm) {
        width: 300%;

        &._1 {
            right: 100% !important;
        }

        &._2 {
            right: 200% !important;
        }
    }

    &._1 {
        right: 0;
    }

    &._2 {
        right: 0;
    }

    .detail {
        margin-left: auto;
    }
}

.com {
    border-bottom: 1px solid rgba(4,39,71,0.2) !important;
    padding-bottom: 5px;
    align-items: flex-end !important;

    img {
        display: none;
    }

    h6 {
        opacity: 0.2;
        cursor: pointer;
    }
}

.com-item {
    display: flex;
    align-items: baseline;
    border-bottom: 1px solid #E5E5E5;
    padding: 7px 0px;
}

.active-member {
    border-bottom: 1px solid rgba(4,39,71,1) !important;
    opacity: 1;

    h6 {
        opacity: inherit;
    }

    .submission-item {
        display: flex;
    }
}

.annual-report-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
}

.icon {
    padding-right: 30%;
    margin-bottom: 30px;

    @media(max-width:@screen-md) {
        width: 300px;
    }
}

.guides-and-procedures-item {
    margin-bottom: 30px;
}

.committee-list {
    .list-col {
        &._0 {
            padding-left: 0px;
        }

        &._2 {
            padding-right: 0px;
        }

        @media(max-width:@screen-md) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.buttons {
    div {
        &:first-child {
            padding-left: 0px;
        }

        &:last-child {
            padding-right: 0px;
        }
        a{
            padding:15px 0px;
        }
    }
}

.annual-report-item:nth-child(odd) {
    padding-left: 0px;

    @media(max-width:@screen-sm) {
        padding-right: 0px;
    }
}

.annual-report-item:nth-child(even) {
    padding-right: 0px;

    @media(max-width:@screen-sm) {
        padding-left: 0px;
    }
}

.view_report_button {
    width: 135px;
}

.committee_buttons{
    div{
        &:first-child{
            padding-left:0px;
        }
        &:last-child{
            padding-right:0px;
        }
    }
}
