.authenticated-layout {
    #contact {
        @media(max-width:@screen-sm){
            text-align:center;
        }
        .contact-item{
            margin-top:10px;
            margin-bottom:30px;
            *{
                margin-bottom:0px;
            }
        }
    }
}

.contact-page-authenticated{
    @media(min-width:@screen-sm){
        .col-md-3{
            &:first-child{
                padding-left:0px;
            }

            &:last-child{
                padding-right:0px;
            }
        }
    }
}
