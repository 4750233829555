.reports {
    margin-bottom:60px;
    .list-col {
        &:nth-child(odd) {
            padding-left: 0px;
        }

        &:nth-child(even) {
            padding-right: 0px;
        }
    }
}


.umbraco-forms-container {
    &:first-child {
        padding-left: 0px;
    }

    &:last-child {
        padding-right: 0px;
    }

    @media(max-width:@screen-sm) {
        padding-left: 0px;
        padding-right: 0px;
    }
}



.reports {
    .list-col {
        @media(max-width:@screen-sm) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.newwelfareassistancerequestreport .umbraco-forms-navigation div:last-child {
    padding-right: 0px !important;
}

.umbraco-forms-navigation {
    .col-6:nth-child(2) {
        padding-right: 0px;
    }
}

.levelofalertness {
    select {
        min-width: 250px;
    }

    @media(max-width:@screen-md) {
        select {
            min-width: unset;
        }

        .help-block {
            display: none;
        }

        label {
            display: block !important;
        }
    }
}

.umbraco-forms-container {

    .details,
    .attachments,
    .responsetimeframe {
        & > label {
            display: none;
        }
    }


    .hasreportedunfitforduty, .hascompanycontact {
        @media(min-width:@screen-md) {
            width: 50%;
        }
    }

    .hasreportedunfitforduty {
        margin-top: 50px;
    }

    .form-group.umbraco-forms-field.longanswer {
        display: block;
    }

    .drag-and-drop-box {
        border: 1px dashed @brand-secondary-light;
        border-radius: 8px;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 14px;

        label {
            cursor: pointer;
            display: flex;
            color: @brand-primary-light;
        }

        input {
            display: none;
        }
    }

    label[for="c9be80bd-3fd8-4f30-b12d-444bbedfdc73"] {
        display: block !important;
    }

    label {
        text-align: left !important;
        margin-left: 15px;
        padding-top: 0px;
        padding-left: 0px;
    }

    .form-group {
        display: flex;
        align-items: center;
    }

    ::placeholder {
        color: @brand-primary-light;
    }


    textarea {
        min-height: 70px !important;
    }

    .checkbox {
        margin-bottom: 5px;
        padding-top: 0px;
        display: flex;


        .yesno-buttons {
            display: flex;
            padding-left: 15px;
            width: 50%;
            margin-left: auto;
            min-width: 200px;

            .btn {
                flex-grow: 1;

                &:active {
                    background-color: @brand-primary;
                }

                &:first-child {
                    margin-right: 1rem;
                }
            }
        }
    }

    .details {
        height: 100%;

        .umbraco-forms-field-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;

            textarea {
                flex-grow: 1;
                margin-bottom: 1rem;
            }
        }
    }

    .help-block {
        font-family: Khula;
        font-size: 16px;
        color: @brand-primary;
        margin-bottom: 0px;
        padding-left: 15px;
    }


    .attachments {
        display: block !important;

        input {
            height: calc(141px + 1rem) !important;
            border-style: dashed;
        }
    }
}

#accommodation-rpt {
    .port,
    .category,
    .categoryother,
    .details {
        label {
            display: none;
        }
    }

    .hoteldescription{
        margin-bottom: 0;
        .help-text-above p{
            padding-left:27px;
        }
    }
}

#meals-rpt, #transport-rpt, #sec {
    .category,
    .categoryother,
    .details,
    .attachments {
        & > label {
            display: none;
        }
    }
}

#fatigue-rpt {

    .fatigueformsubmitted,
    .hasreportedunfitforduty,
    .hascompanycontact,
    .concerns {
        & > label {
            display: none;
        }
    }
}

#general-rpt{
    .title{
        & > label{
            display:none;
        }
    }
}

.newwelfareassistancerequestreport {
    .responsetimeframe{
        display:block;
    }
}

