.about-page {

    .nav-tabs {
        border: none;

        li {
            text-align: center;

            > div {
                background-color: @brand-primary;
            }

            h2 {
                .h6();
                text-transform: uppercase;
            }

            a {
                border: none;
                margin: 0;
                border-radius: 0px 0px 0 16px;
                background-color: @white;
                display: block;
                padding-top: 15px;
                padding-bottom: 15px;

                &:active, &:focus, &:hover {
                    border: none;
                    text-decoration: none;
                }

                h2 {
                    margin: 0;
                    font-size: 16px !important;
                }
            }

            &.active {
                > div {
                    background-color: @white;
                }

                a {
                    background-color: @brand-primary;
                    border-radius: 8px 8px 0 0;
                    display: block;

                    &:active, &:focus, &:hover {
                        border: none;
                    }

                    h2 {
                        color: @brand-secondary-light;
                    }
                }
            }

            &:first-child {
                padding-right: 0;
                padding-left: 0;

                > div {
                    a {
                        border-radius: 0px 0px 16px 0;
                    }
                }

                &.active {
                    > div {
                        a {
                            border-radius: 8px 8px 0 0;
                        }
                    }
                }
            }

            &:nth-child(2) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .tab-pane {
        position: relative;

        .intro-text-container {
            background-color: @brand-primary;
            color: @white;
            padding: 20px;
            border-radius: 8px;
            margin-top: -5px;

            @media(min-width: @screen-sm) {
                padding: 35px 60px 30px;
            }

            p {
                color: @white;
                margin-bottom: 0;
            }
        }

        &#overview {
            .copy-area {
                @media(min-width: @screen-sm) {
                    padding: 0 60px
                }
            }
        }

        &#committee {
        }

        .bottom-content-container {
            @media(min-width: @screen-sm) {
                padding: 0 60px
            }

            .commitee-management-list {
                list-style-type: none;
                padding: 0;

                @media(min-width: @screen-sm) {
                    column-count: 2;
                    column-gap: 50px;
                }

                @media(min-width: @screen-md) {
                    column-count: 3;
                }

                li {
                    padding: 20px 0 5px;
                    border-bottom: 1px solid @brand-secondary;
                    font-weight: bold;
                    color: @brand-primary;
                    column-fill: auto;
                    column-break-inside: avoid;
                    -webkit-column-break-inside: avoid;
                }
            }
        }

        .commitee-members {
            list-style-type: none;
            padding: 0;
            text-align: center;
            padding: 0;
            margin-left: -15px;
            margin-right: -14px;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .commitee-members-item {
                float: none;
                .display-inline-block();
                margin-bottom: 15px;
                cursor: pointer;
                /*.transition(all .5s);*/
                padding: 0 15px;
                -webkit-box-flex: 0;
                flex: 0 0 50%;

                @media(min-width: @screen-sm) {
                    flex: 0 0 33%;
                }

                @media(min-width: @screen-md) {
                    flex: 0 0 20%;
                }

                &:hover {
                    .commitee-member-details {
                        opacity: 1 !important;
                    }
                }

                .bg-container {
                    background-color: @brand-primary;
                    .border-radius(15px);
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                    background-position: center top;
                    height: 330px;
                    position: relative;

                    @media(max-width: @screen-xs-max) {
                        height: 250px;
                    }

                    .commitee-member-details {
                        padding: 15px;
                        .border-radius(15px);
                        text-align: left;
                        height: 100%;
                        width: 100%;
                        background-color: rgba(4,39,71, .9);
                        .transition(all .5s);
                        opacity: 0;

                        .inner-caption {
                            position: relative;
                            top: 50%;
                            bottom: initial;
                            right: initial;
                            left: 0;
                            transform: translatey(-50%);
                            text-align: left;

                            h4 {

                                &.name {
                                    text-transform: uppercase;
                                    color: @brand-secondary-light;
                                    font-weight: 900;
                                }

                                &.position {
                                    color: @white;
                                }
                            }

                            hr {
                                width: 50px;
                                height: 2px;
                                background-color: @white;
                                margin: 5px auto 5px 0;
                            }

                            .member-profile {
                                display: none;
                            }
                        }
                    }
                }

                .committe-member-profile {
                    .transition (all 1.5s);
                    position: absolute;
                    left: 0;
                    background-color: @brand-primary;
                    margin: 20px 0;
                    padding: 20px;
                    text-align: left;
                    color: white;
                    border-radius: 15px;
                    width: 100%;

                    @media(min-width: @screen-sm) {
                        padding: 30px 60px;
                    }

                    p {
                        margin: 0;
                        color: white;
                    }
                }
            }
        }
    }
}

#committeeMemberModal {
    .modal-body {
        text-align: left;

        h2 {
            color: @brand-secondary-light;
            margin-bottom: 0;
        }

        h3 {
            color: @white;
            margin-top: 0;
        }

        hr {
            width: 50px;
            height: 2px;
            background-color: @white;
            margin: 5px 0;
        }

        p {
            margin-top: 20px;
        }
    }
}
