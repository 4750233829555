.account-page-authenticated {

    .list-item {
        min-height: 70px;
        align-items: center;
        padding-bottom: 10px;
        padding-top: 10px;

        p, h6 {
            margin-bottom: 0px;
        }

        p {
            margin-top: 9px;
        }

        h6 {
            margin-top: 0px;
        }

        .col-12{
            padding-left:0px;
            padding-right:0px;
        }
    }

    #preferences {
        h6 {
            margin-right: auto;
        }
    }

    .profile-pic-container {
        height: 140px;
        overflow: hidden;
        border-radius: 5px;
        margin-top:10px;

        @media(max-width:@screen-sm) {
            display: flex;
            height: 200px;
            margin-top:20px;
            margin-bottom:20px;
            width: 100%;
        }

        img {
            width: 120px;

            @media(max-width:@screen-sm) {
                margin: auto;
                border-radius: 5px;
            }
        }
    }
}

#prefence_select {
    min-width: 215px;

    @media(max-width:@screen-sm) {
        margin-top: 9px;
    }
}

.fa-layers {
    margin-right: 7px;
}
