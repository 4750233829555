
.member-row {

    h2{
        @media(max-width:@screen-md){
            text-align:center;
        }
    }
    &.member-blue-row {
        background-color: @brand-plane;

        .container {
            flex-direction: row-reverse;
            @media(max-width: @screen-lg){
                display: block !important;
            }

            .member-image {
                height: auto;
                width: auto;
                align-self: flex-end;
                margin:auto;
            }

            .member-copy {
                * {
                    color: white;
                }

                h2 {
                    color: @brand-primary;
                }

                .btn {
                    border: 1px solid white;

                    &:hover {
                        background-color: @brand-primary;
                    }
                }
            }
        }
    }

    .container {
        display: flex;

        .member-image {
            height: auto;
            width: auto;
            align-self: flex-end;
        }

        @media(max-width:@screen-lg) {
            flex-direction: column !important;

            .member-image {
                align-self: center;
            }
        }

        .member-copy {
            align-self: flex-end;

            .btn {
                margin-top: 10px;
                margin-bottom: 30px;
            }

            ul {
                padding-left: 1.9rem !important;
            }
        }
    }
}

.apply_button {
    @media(max-width:@screen-md) {
        text-align: center;
    }
}
