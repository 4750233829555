.authenticated-layout {


    footer {
        .container {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        #footer-submit {
            align-items: center;
            display: flex;

            .fa-chevron-right {
                margin-left: 30px;
            }
        }

        .form-group {
            display: flex;
            align-items: center;
            border-bottom: 1px solid @brand-secondary-light;

            input {
                border: none;
                color: white;
                vertical-align: baseline;
            }
        }

        .footer-social {
            display: flex;
            justify-content: space-between;
            max-width: 200px;

            img {
                height: 30px;
            }
        }

        .footer-info {
            p {
                color: white;
            }
        }
    }
}
