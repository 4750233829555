// Resources page accordion

.main_content:not(:empty){
    margin: 20px 0px;
    *{
        margin-bottom:0px !important;
    }
}

.subsection-heading {
    & > .subsection-icon-open {
        display: inline-block;
    }

    & > .subsection-icon-closed {
        display: none;
    }

    &.collapsed {
        & > .subsection-icon-open {
            display: none;
        }

        & > .subsection-icon-closed {
            display: inline-block;
        }
    }
}


.panel-group {
    margin-bottom:1px;
    .panel {
        box-shadow: none;
        border-radius:0px !important;
        &:first-child {
            border-top: 1px solid @brand-secondary;
        }
    }
}


.panel-heading {
    display: flex;
    justify-content: space-between;
    align-items:center;
    border-radius:unset;
    padding-left:0px;
    svg[data-prefix="fal"] {
        color: @brand-secondary;
        align-self: center;
    }
}


.resources-buttons {
    margin-left: -2px;
    margin-right: -2px;

    div {
        padding-left: 2px;
        padding-right: 2px;

        .r-b {
            padding-left: 0px !important;
            padding-right: 0px !important;

            @media(max-width:@screen-lg) {
                margin-bottom: 1rem;
            }
        }
    }
}

.panel-item{
    &:nth-child(odd){
        padding-left:0px;
    }

    &:nth-child(even){
        padding-right:0px;
    }

    & > .panel-heading{
        padding:0px;
    }

    & > .panel-body{
        padding-left:0px;
        padding-right:0px;
    }
}


.panel-collapse > .panel-body{
    padding-top:0px;
    padding-bottom:0px;
}

.TAX{
    .panel-item:last-child{
        flex: 0 0 100%;
        max-width:unset;
    }
}
