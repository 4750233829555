.contact-page {

    .contact-info-row {
        background: url(../../images/world-map.png) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-position: right 59px;
        min-height: 600px;

        @media(min-width: @screen-md) {
            -webkit-background-size: 88%;
            -moz-background-size: 88%;
            -o-background-size: 88%;
            background-size: 88%;
            background-position: center;
        }

        .contact-info-item {
            border-top: 1px solid @brand-secondary;
            padding-top: 35px;
            text-align: center;
            margin-bottom: 40px;
            max-width: 250px;
            margin-left: auto;
            margin-right: auto;
            height: 200px;

            @media(min-width: @screen-sm) {
                text-align: left;
            }

            @media(min-width: @screen-md) {
                max-width: 200px;
                margin-bottom: 0;
                margin-left: 0;
            }

            h6 {
                text-transform: uppercase;
                margin-top: 20px;
                margin-bottom: 0;

                &:first-child {
                    margin-top: 0px;
                }
            }

            p {
                margin: 0;
                font-size: 15px;
            }
        }

        .btn {
            margin-top: 10px;
            width: 250px;
            margin-left: auto;
            margin-right: auto;
            display: block;

            @media(min-width: @screen-md) {
                margin-top: 50px;
                width: auto;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
    }
}
