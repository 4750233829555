body {
    background-color: @body-color;
    font-family: 'Khula', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: @gray;
    overflow-x: hidden;
}

main {
    margin-top: 126px;
}

#member-login {
    margin-bottom: 15px;
}

.modal-backdrop {
    position: initial;

    // Do not show the darkened modal background.
    // Fixes login for IE11.
    display: none;
}

.pointer{
    cursor:pointer;
}

// navbar override

@media(max-width: 420px) {
    .navbar-brand {
        float:none;
    }
}

.news-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .read-more {
        margin-top: auto;
    }
}

.upper {
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Oswald', sans-serif;
}


.blue {
    color: @brand-primary !important;
}

.gold-light {
    color: @brand-secondary-light !important;
}

.gold {
    color: @brand-secondary !important;
    font-family: 'Khula', sans-serif;
}

.pl-0 {
    @media(min-width:@screen-md) {
        padding-left: 0px;
    }
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

a {
    color: @brand-primary;
}

p {
    color: @gray;
    line-height: 1.5;
    margin-bottom: 20px;

    &.small-text {
        font-size: .8em;
    }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Oswald', sans-serif;
    line-height: 1.5;
    color: @brand-primary;
}

h1, .h1 {
    font-size: 45px;
    line-height: 1;
    font-weight: 600;
    color: @brand-primary;
    text-transform: uppercase;

    @media(min-width: @screen-sm) {
        font-size: 78px;
    }
}

h2, .h2 {
    font-size: 30px;
    font-weight: bold;
    color: @brand-primary;
}

h6, .h6 {
    font-size: 18px;
    font-weight: 600;
}

.link-button {
    margin-bottom: 30px;
}

.subsection-download {
    margin-bottom: 30px;
}

.subsection-btn {
    width: 130px;
}

.btn {
    height: 47px;
    border: 1px solid @brand-secondary-light;
    color: @brand-primary;
    background-color: transparent;
    padding: 15px 20px;
    font-family: 'Khula', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    .border-radius(10px);
    line-height: 1.3;
    .transition(all .5s);

    &:hover, &:focus, &:active {
        background-color: @brand-secondary-light;
        color: @white !important;
    }

    &.active {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.gold-text {
        color: @brand-secondary;

        &:hover, &:focus, &:active {
            color: @white;
        }
    }

    &.white-text {
        color: @white;
    }

    &.btn-large {
        @media(min-width: @screen-sm) {
            font-size: 18px;
            height: 70px;
            padding: 25px 20px;
        }
    }

    &.btn-inverted {
        background-color: @brand-secondary-light;
        border-color: @brand-secondary-light;
        color: @brand-primary;

        &:hover, &:focus, &:active {
            background-color: @brand-primary;
            color: @white;
            border-color: @brand-primary;
        }
    }
}

.form-control {
    background-color: transparent;
    .border-radius(0px);
    padding-bottom: 5px;
    padding-left: 4px;
    color: @brand-secondary-light;
    font-size: 16px;
    line-height: 1.5;
    border: none;
    border-bottom: 1px solid @white;
    font-family: 'Oswald', sans-serif;

    &:focus {
        font-weight: bold;
        border-color: @white;
    }
}

.form-control2 {
    background-color: transparent;
    .border-radius(10px);
    padding-bottom: 5px;
    padding-left: 20px;
    height: 47px;
    padding: 11px 20px;
    color: @brand-primary;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid @brand-secondary-light;
    font-family: 'Khula', sans-serif;

    &:focus {
        font-weight: bold;
        border-color: @brand-secondary-light;
    }

    &::placeholder {
        color: @brand-primary;
    }

    &.reduced-padding {
        padding-left: 10px;
        padding-right: 10px;
    }

    &.half-size {
        padding: 3.5px 20px;
        height: auto;
    }

    &.error {
        border-color: red;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    padding-left: 5px;
    background-image: url(../../images/drop-down-icon.png);
    background-position: right center;
    background-repeat: no-repeat;
}

.form-group-small {
    margin-bottom: 5px;
}

/* Inputs styling
 Check box*/
.checkbox-container {
    position: relative;
    display: block;

    input[type=checkbox] {
        position: absolute;
        left: -99999px;
    }

    label {
        color: @brand-primary;
        margin-left: 0;
        padding-top: 10px;
    }

    input[type=checkbox] + label {
        position: relative;
        padding-left: 60px;
        cursor: pointer;
        margin-bottom: 20px;
        font-weight: normal;
        line-height: 25px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: -1px;
            width: 47px;
            height: 47px;
            border: 1px solid @brand-secondary-light;
            .border-radius(10px);

            &.error {
                border-color: red;
            }
        }
    }

    input[type=checkbox]:checked + label {
        &:after {
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: 40px;
            color: @brand-primary;
            transition: all .2s;
            content: '\2713';
            font-weight: bold;
        }
    }
}

label {
    color: @brand-primary;
    font-weight: normal;
}

.btn-arrow {
    background-color: transparent;
    border: none;
    color: @brand-secondary-light;
    font-size: 0px;

    &:hover, &:focus, &:active {
        background-color: transparent;
        border: none;
    }
}

.input-button-stack {
    position: relative;
    margin-bottom0: 0;

    button {
        //width: 14px;
        //height: 14px;
        //float: right;
        //position: absolute;
        //vertical-align: middle;
        //right: 15px;
        color: #ffffff;
        border: 1px solid @brand-secondary-light;
        margin-top:15px;
        border-radius: 10px;
        //top: 7px;
        //border-top: 2px solid @brand-secondary-light;
        //border-right: 2px solid @brand-secondary-light;
        //transform: rotate(45deg);
        &:hover, &:focus, &:active {
            border-color: @brand-secondary-light;
            //border-top: 2px solid @brand-secondary-light;
            //border-right: 2px solid @brand-secondary-light;
            color: #ffffff;
        }
    }
}

.row-spacer-top {
    margin-top: 70px;

    @media(min-width: @screen-sm) {
        margin-top: 100px;
    }
}

.row-spacer-top-half {
    margin-top: 35px;

    @media(min-width: @screen-sm) {
        margin-top: 50px;
    }
}

.row-spacer-bottom {
    margin-bottom: 70px;

    @media(min-width: @screen-sm) {
        margin-bottom: 100px;
    }
}

.row-spacer-bottom-half {
    margin-bottom: 35px;

    @media(min-width: @screen-sm) {
        margin-bottom: 50px;
    }
}

.full-width {
    width: 100%;
}

.page-header {
    @media(max-width: @screen-md) {
        text-align: center;
    }
}

ul.nav-pills-btn {
    li {
        a {
            height: 47px;
            border: 1px solid @brand-secondary-light;
            color: @brand-primary;
            background-color: transparent;
            padding: 15px 20px;
            font-family: 'Khula', sans-serif;
            text-transform: uppercase;
            font-size: 16px;
            .border-radius(10px);
            line-height: 1.3;
            .transition(all .5s);

            &:hover, &:focus, &:active {
                background-color: @brand-secondary-light;
                color: @white !important;
            }

            &.active {
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }

        &.active {
            a {
                background-color: @brand-secondary-light;

                &:hover {
                    background-color: @brand-secondary-light;
                }
            }
        }

        @media(min-width: @screen-sm) {
            padding-left: 15px;
            padding-right: 15px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
}

#loginModal {
    .btn-arrow {
        top: 39px !important;
    }
}
