.authenticated-layout {

    main{
        margin-top:183px;
        padding-left:30px;
        padding-right:30px;
    }

    .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .auth_news-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:nth-child(even) {
            padding-right: 0px;

            @media(max-width:@screen-sm) {
                padding-left: 0px;
            }
        }

        &:nth-child(odd) {
            padding-left: 0px;

            @media(max-width:@screen-sm) {
                padding-right: 0px;
            }
        }
    }

    .row:before, .row:after {
        display: none;
    }

    .buttons {
        div {
            @media(max-width:@screen-sm) {
                margin-bottom: 1rem;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .btn {
        @media(max-width:@screen-lg) {
            font-size: 14px;
        }
    }

    > .container:nth-child(2) {
        min-width: 300px;
        min-height:300px;
    }

    .news-collection-item {
        margin-bottom: 30px;
    }

    .form-control {
        border: 1px solid @brand-secondary-light;
        border-radius: 8px;
        min-height: 47px;
        background-color: transparent;
        border-radius: 10px;
        background-clip: padding-box;
        padding-bottom: 5px;
        padding-left: 20px;
        height: 47px;
        padding: 12px 20px;
        color: @brand-primary-light;
        font-size: 14px;
        vertical-align: text-bottom;
        line-height: 1.5;
        font-family: 'Khula',sans-serif;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        padding-left: 5px;
        background-image: url(../../images/drop-down-icon.png);
        background-position: right center;
        background-repeat: no-repeat;
    }

    .page-header {
        border-bottom: none !important;
        margin-bottom: 10px;
        margin-top: 20px;

        @media(min-width:@screen-md) {
            display: flex;
        }

        h1 {
            margin-left: -2px;
            font-size: 50px;
        }
    }

    .article-img {
        width: 100%;
        margin-bottom: 30px;
    }

    .filtr-item {
        height: 100%;
        transform: unset !important;
    }

    header {
        @media(max-width:@screen-md) {
            background-color: @brand-primary;

            a {
                color: white;
            }
        }
    }

    logout {
        color: white;
    }

    #your-news {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        margin-bottom: 15px;

        h6 {
            margin-top: 10px !important;
            margin-bottom: 10px !important;
        }
    }

    input[type="submit"] {
        background-color: @brand-secondary-light;
    }

    .button-row-filter {
        width: 100%;
    }


    p {
        font-family: 'Khula', sans-serif;
    }

    .positive {
        color: #1DD09D;
    }

    .negative {
        color: #FF0000;
    }

    .top-border {
        border-top: 1px solid #927D54;
    }

    .bottom-border {
        border-bottom: 1px solid #927D54 !important;
    }


    .bottom-border-light {
        border-bottom: 1px solid @brand-secondary-light;
    }


    .btn.active {
        background-color: @brand-secondary-light;
    }

    .currencies {
        .row {
            margin-right: 0px;
            margin-left: 0px;
        }

        .list-item {
            .top-border
        }
    }

    .currencies {

        .list-col {
            .list-item:last-child {
                .bottom-border
            }

            margin-bottom: 50px;
            border-bottom: 1px solid @brand-secondary;

            @media(min-width:@screen-md) {
                border-bottom: none !important;

                &:first-child {
                    padding-right: 50px !important;
                }

                &:last-child {
                    padding-left: 50px !important;
                }
            }
        }

        .gold {
            margin-bottom: 0px;
        }

        .list-col {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .resources-list, .reports {
        .list-col {
            &:first-child {
                @media(min-width:@screen-sm) {
                    padding-right: 50px !important;
                }
            }

            &:last-child {
                @media(min-width:@screen-sm) {
                    padding-left: 50px !important;
                }
            }
        }

        a {
            .upper
        }

        .list-item:last-child {
            .bottom-border
        }
    }

    

    .list {
        .list-col {

            @media(max-width:@screen-sm) {
                &:last-child {
                    .list-item:first-child {
                        border-top: none !important;
                    }
                }
            }

            .list-item {
                height: 60px;
                display: flex;
                align-items: center;

                &.com:last-child {
                    .bottom-border;
                }
            }
        }
    }







    @media(max-width:@screen-sm) {
        .news-item {
            padding-left: 0px;
            padding-right: 0px;
        }
    }


    .triangle-overlay {
        width: 50%;
        height: 200%;
        border-style: solid;
        border-width: 500px 250px 250px 0;
        border-color: transparent white transparent transparent;
        position: absolute;
        top: 0;
        right: 0;
    }

    .article-thumb-container {
        position: relative;
        overflow: hidden;
    }


    #download, #contact {
        @media(max-width:@screen-sm) {
            div[class^="col"] {
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
        }

        img {
            margin-bottom: 10px;
            max-width: 60%;

            @media(max-width:@screen-sm) {
                width: 20%;
            }
        }
    }


    .button-row-filter {
        div:first-child {
            padding-left: 0px;
        }

        div:last-child {
            padding-right: 0px;
        }
    }



    .news-carousel-container {
        .row {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    #welfare {
        .umbraco-forms-container {
            padding-left: 0px;
            padding-right: 0px;
        }
    }


    .fatigueformsubmitted,
    .hasreportedunfitforduty,
    .hascompanycontact {
        .help-block {
            display: none;
        }
    }

    .yes-no-label {
        font-family: Khula;
        font-size: 16px;
        color: #042747;
        width: 50%;
    }


    .img-col {
        &:nth-child(odd) {
            padding-left: 0px;
        }

        &:nth-child(even) {
            padding-right: 0px;
        }

        @media(max-width:@screen-sm) {
            padding-left: 0px;
            padding-right: 0px;
        }

        & + .article-col {
            padding-left: 0px;


            @media(max-width:@screen-sm) {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .article-col.col-12 {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.empty-container {
    &.deal-preview-img {
        height: 200px;
        margin-bottom: 1rem
    }
}

.article-buttons {
    div {
        margin-bottom: 1rem;

        @media(max-width:@screen-sm) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.deal-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    > p {
        margin-bottom: 0px;
    }

    &:nth-child(odd) {
        padding-left: 0px;

        @media(max-width:@screen-sm) {
            padding-right: 0px;
        }
    }

    &:nth-child(even) {
        padding-right: 0px;

        @media(max-width:@screen-sm) {
            padding-left: 0px;
        }
    }

    img{
        width:250px;
        height:200px;
    }
}

.deal_img {
        width: 250px;
        height: 200px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
}

.search-page_search-form{
    margin-top:30px;
}
