.aipa-membership-page {

    .card {
        position: relative;
        margin-bottom: 24px;
    }

    .membership-type-selection {
        .membership-type {
            margin-bottom: 15px;

            @media(min-width: @screen-md) {
                margin-bottom: inherit;
            }
        }
    }

    .step-progress .step-slider {
        background-color: @light-gray;
        margin-bottom: 20px;
        .border-radius(15px);

        &:before, &:after {
            display: table;
            content: " ";
        }

        &:after {
            clear: both;
        }

        &.items-4 {
            .step-slider-item {
                width: 25%;
            }
        }

        &.items-5 {
            .step-slider-item {
                width: 20%;
            }
        }

        &.items-6 {
            .step-slider-item {
                width: 16.66%;
            }
        }

        div.step-slider-item {
            text-align: center;
            position: relative;
            float: left;
            background-color: @light-gray;
            padding-top: 15px;
            padding-bottom: 15px;
            color: @brand-primary;

            &.active {
                background-color: @brand-secondary-light;
            }

            &.current {
                font-weight: bold;
                .border-radius(0 15px 15px 0);
            }

            &:first-child {
                .border-radius(15px 0 0 15px);
                background-color: @brand-secondary-light;

                &.current {
                    .border-radius(15px);
                }
            }

            &:last-child {
                .border-radius(0 15px 15px 0);
            }
        }
    }

    .step-progress .step-slider .step-slider-item:after {
        z-index: 2;
        transition: all 0.3s ease-out 0.5s;
        -webkit-transition: all 0.3s ease-out 0.5s;
    }

    .step-progress .step-slider .step-slider-item:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 1px;
        z-index: 1;
        -webkit-transition: all 0.5s ease-out;
    }

    .step-progress .step-slider .step-slider-item.active:before {
        width: 100%;
    }

    .step-progress .step-slider .step-slider-item.active:after {
        border-color: #FF8F00;
    }

    .step-content {
    }

    .step-content .step-content-foot {
    }

    .step-content .step-content-foot button {
    }

    .step-content .step-content-foot button:active {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .step-content .step-content-foot button.out {
        display: none;
    }

    .step-content .step-content-foot button.disable {
        background-color: #ECEFF1;
    }

    .step-content .step-content-foot button.active {
    }

    .step-content .step-content-body {
        .step-content-title {
            color: @brand-primary;
            font-family: 'Oswald', sans-serif;
            font-size: 1.8rem;
            font-weight: bold;
            line-height: 1.5;
            border: none;
        }
    }

    .step-content .step-content-body.out {
        display: none;
    }

    form {
        margin-top: 20px;

        h6 {
            margin-bottom: 15px;

            & + p {
                margin-top: -5px;
            }
        }

        label {
            line-height: 1;

            @media(min-width: @screen-sm) {
                line-height: 47px;
            }
        }

        .signature-input {
            height: 109px;
            min-height: 109px;
            padding: 0;

            canvas {
                max-height: 109px;
            }
        }

        .padding-lr-5 {
            @media(min-width: @screen-sm) {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .date-fix {
            text-align: center;
        }
    }

    .summary-section {

        .col-title {
            .border-radius(16px);

            @media(min-width: @screen-md) {
                .border-radius(0);
            }
        }

        :first-child {
            .col-title {
                @media(min-width: @screen-md) {
                    border-top-left-radius: 16px;
                    border-bottom-left-radius: 16px;
                }
            }
        }

        :last-child {
            .col-title {
                @media(min-width: @screen-md) {
                    border-top-right-radius: 16px;
                    border-bottom-right-radius: 16px;
                }
            }
        }

        .row {
            .round-left:first-child, .round-right:first-child {
                @media(min-width: @screen-md) {
                    padding-right: 0;
                }
            }

            .round-left:nth-child(2), .round-right:nth-child(2) {
                @media(min-width: @screen-md) {
                    padding-left: 0;
                }
            }

            .round-left, .round-right {
                .user-data {
                    padding-left: 10px;
                }
            }
        }

        .col-title {
            background-color: @brand-primary;
            color: @white;
            font-weight: bold;
            padding: 10px;
            margin-bottom: 10px;
        }

        .user-data {
            margin-bottom: 20px;

            > .row {
                padding-top: 10px;
                padding-bottom: 10px;

                .title-col {
                    font-weight: bold;
                }
            }
        }

        .left-col {
            @media(min-width: @screen-md) {
                padding-right: 0;

                .col-title {
                    .border-radius(16px 0px 0px 16px);
                }

                .col-title, .user-data {
                    padding-left: 60px;
                }
            }
        }

        .right-col {
            @media(min-width: @screen-md) {
                padding-left: 0;

                .col-title {
                    .border-radius(0px 16px 16px 0px);
                    padding-left: 0;
                }

                .col-title, .user-data {
                    padding-right: 60px;
                }
            }
        }
    }
}

.member-row {
    .member_image_container{
        .desktop{
            display:block;
        }
        .mobile{
            display:none;
        }
        @media(max-width:@screen-md){
            .desktop{
                display:none;
            }
            .mobile{
                display:block;
            }
        }
    }
}
