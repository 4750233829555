.altitude-page {
    .altitude-page__item {
        .altitude-page__item-preview {
            img {
                max-width: 125px;
                margin-right: 20px;
                margin-top:10px;
            }
        }
    }
}

#news_categories{
    margin-bottom:20px;
}
