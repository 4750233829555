
.authenticated-layout .row.financial-currencies {
    margin-left: -15px !important;
    margin-right: -15px !important;
}

.financial-currencies {
    margin-bottom: 40px;
    width:100%;  

    .no-padding {

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .list-title {
        padding: 10px 15px;
        border-top: 1px solid #927D54;
        border-bottom: 1px solid #927D54;

        h6 {
            margin-bottom: 0;
        }
    }

    .list-table {
        width: 100%;

        tr {
            border-bottom: 1px solid #927D54;

            td {
                padding: 10px 15px;
                &:first-child{
                    width:15%;
                }
                &:last-child{
                    width:25%;
                }
            }
        }

        tbody {

            tr{
                &:nth-child(even) {
                    background-color: @light-gray;
                }
            }
        }
    }
}

@media (max-width:@screen-md){
    .financial-currencies {

        .no-padding {
            padding-left: 0;
            padding-right: 0;

            thead {
                display: none;
            }

            &:first-child {
                thead {
                    display: table-header-group;
                }
            }
        }
    }
}
