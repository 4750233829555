.authenticated-layout {

    .container {
        padding-right: 30px;
        padding-left: 30px;
    }

    header {
        #logout-btn {
            border: none;
            padding-right: 0px;

            &:hover {
                background-color: transparent;
            }

            &:focus {
                background-color: transparent;
            }
        }

        .navbar-brand {
            img {
                width: 200px;

                @media(min-width:@screen-sm) {
                    width: 400px;
                }
            }
        }



        .navbar {
            background-color: @brand-primary !important;
            margin-bottom: 0px;
            border: none;
            border-radius: 0;

            .container {
                justify-content: flex-start;
                flex-wrap: nowrap;
                @media(max-width:@screen-sm-max){
                    flex-wrap: wrap;
                }

                .navbar-header {
                    margin-right: auto;
                    width: initial;
                }

                .mobile-nav {
                    color: white;

                    @media(min-width:@screen-md) {
                        display: none;
                    }
                }

                #header-icons {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-left: auto;
                    margin-top: 20px;

                    @media(min-width:@screen-md) {
                        max-width: 400px;
                        margin-top: 0px;
                    }

                    img {
                        height: 28px;
                    }
                }
            }
        }
    }

    .nav-link {
        border-bottom: 1px solid @brand-primary;
        padding-left: 15px;

        @media(min-width:@screen-md) {
            border-bottom: 1px solid white;
        }

        @media(min-width:@screen-sm) {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        &:hover {
            border-bottom: 1px solid @brand-secondary-light;
        }
    }

    .navbar-nav {
        margin-bottom: 0px;
        width: 100%;
    }

    @media (min-width: @screen-md) {
        #navbar {
            display: flex !important;
            height: auto !important;
            padding-bottom: 0;
            overflow: visible !important;
        }

        .navbar-nav {
            padding-left: 0px;
            padding-right: 0px;
            width: 100%;
            justify-content: space-between;
        }
    }
}
